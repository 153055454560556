import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import {TemplateRCDataFromRequest} from 'core/RateConfirmation/types';

import {PROGRESS_STATUSES} from 'pages/Template/constants';

import {sendSign, getConnection} from './asyncActions';

type State = {
    status?: typeof PROGRESS_STATUSES[keyof typeof PROGRESS_STATUSES];
    statusConnection: typeof PROGRESS_STATUSES[keyof typeof PROGRESS_STATUSES];
    templateData?: TemplateRCDataFromRequest;
    validConnection?: boolean;
};

const defaultState: State = {
    statusConnection: PROGRESS_STATUSES.PENDING,
    status: undefined,
    validConnection: undefined,
};

const loadsAdapter = createEntityAdapter();

export const listSlice = createSlice({
    name: 'qualityService',
    initialState: loadsAdapter.getInitialState(defaultState),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConnection.fulfilled, (state, action) => {
            state.validConnection = true;
            state.templateData = action.payload;
            state.statusConnection = PROGRESS_STATUSES.FULFILLED;
        });
        builder.addCase(getConnection.pending, (state) => {
            state.statusConnection = PROGRESS_STATUSES.PENDING;
        });
        builder.addCase(getConnection.rejected, (state) => {
            state.validConnection = false;
            state.statusConnection = PROGRESS_STATUSES.REJECTED;
        });
        builder.addCase(sendSign.fulfilled, (state) => {
            state.status = PROGRESS_STATUSES.FULFILLED;
        });
        builder.addCase(sendSign.pending, (state) => {
            state.status = PROGRESS_STATUSES.PENDING;
        });
        builder.addCase(sendSign.rejected, (state) => {
            state.status = PROGRESS_STATUSES.REJECTED;
        });
    },
});

export default listSlice.reducer;
