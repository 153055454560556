import {AxiosResponse} from 'axios';
import restApi from 'services/api';

type SignParams = {
    author: string;
    hash: string;
};

type ConnectParams = {
    hash: string;
};

export const checkConnectionRequest = (params: ConnectParams): Promise<AxiosResponse> =>
    restApi.post(`/get-rc`, params);

export const sendSignRequest = (params: SignParams): Promise<AxiosResponse> => restApi.put(`/sign-rc`, params);
