import React from 'react';

import Router from './routing';
import Header from './components/ui/Header';

import styles from './styles.module.scss';

const App: React.FC = () => {
    return (
        <div className={styles.layout}>
            <Header />
            <Router />
        </div>
    );
};

export default App;
