import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import trimStart from 'lodash/trimStart';

import {DocumentFormValues} from 'pages/Template/types';
import {sendSign, getConnection} from 'pages/Template/slice/asyncActions';

const useTemplateActions = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const hash = trimStart(location.pathname, '/');

    return {
        getConnection: () => dispatch(getConnection({hash})),
        sendSign: (values: DocumentFormValues) => dispatch(sendSign({...values, hash})),
    };
};

export default useTemplateActions;
