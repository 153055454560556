import {createAsyncThunk} from '@reduxjs/toolkit';
import {AppState} from 'store';

import {TemplateRCDataFromRequest} from 'core/RateConfirmation/types';

import * as requests from 'pages/Template/requests';

export const sendSign = createAsyncThunk<void, {author: string; hash: string}, {state: AppState}>(
    'qualityService/sendSign',
    async (params) => {
        const {data} = await requests.sendSignRequest(params);
        return data;
    },
);

export const getConnection = createAsyncThunk<TemplateRCDataFromRequest, {hash: string}, {state: AppState}>(
    'connection/getConnection',
    async (params) => {
        const {data} = await requests.checkConnectionRequest(params);
        return data;
    },
);
