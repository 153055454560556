import React, {Suspense, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import Spinner from 'components/ui/Spinner';

import useTemplateActions from 'pages/Template/hooks/useTemplateActions';

const TemplatePage = React.lazy(() => import('../pages/Template'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));

export default () => {
    const {getConnection} = useTemplateActions();

    useEffect(() => {
        getConnection();
    }, [getConnection]);

    return (
        <Suspense fallback={<Spinner />}>
            <Switch>
                <Route exact path="/:string" component={TemplatePage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
};
