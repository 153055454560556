import {persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {configureStore} from '@reduxjs/toolkit';
import {createLogger} from 'redux-logger';

import rootReducer from 'store/slices';

const persistConfig = {key: 'testimonial-service', version: 1, storage, whitelist: ['registry']};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware: any = process.env.NODE_ENV === 'development' ? createLogger({collapsed: true}) : [];

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(loggerMiddleware),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.NODE_ENV !== 'production' && module.hot) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    module.hot.accept('./slices', () => store.replaceReducer(rootReducer));
}

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
