import React from 'react';

import Box from '@mui/material/Box';
import {common} from '@mui/material/colors';

import {ReactComponent as Logo} from './Logo.svg';

const Header: React.FC = () => {
    return (
        <Box
            component="div"
            sx={{
                height: '64px',
                lineHeight: '64px',
                textAlign: 'center',
                backgroundColor: common.white,
            }}
        >
            <Logo width="126" height="32" style={{verticalAlign: 'middle'}} />
        </Box>
    );
};

export default Header;
