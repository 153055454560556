import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './fonts.scss';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    fontWeight: '700',
                    minWidth: '200px',
                    backgroundColor: '#039BE5',
                    color: '#fff',
                    '&.Mui-disabled': {
                        backgroundColor: '#E5E8E9',
                        color: '#B0BFC5',
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: '0 60px',
                    height: '78px',
                    backgroundColor: '#15244B',
                    color: '#FFFFFF',
                },
            },
        },
    },
    typography: {
        h4: {
            color: '#039BE5',
        },
        h6: {
            fontSize: '14px',
            fontWeight: '600',
        },
        body2: {
            color: '#84939A',
            fontSize: '14px',
            lineHeight: '22px',
            h2: {
                fontSize: '18px',
                color: '#2F333E',
                lineHeight: '24px',
                margin: '10px 0',
            },
            svg: {
                fontSize: '30px',
            },
            button: {
                fontSize: '16px',
                fontWeight: '700',
                minWidth: '200px',
                backgroundColor: '#039BE5',
            },
        },
        body1: {
            color: '#353A45',
            fontSize: '16px',
            span: {
                color: 'red',
            },
            input: {
                fontFamily: 'Alex Brush',
                fontSize: '24px',
            },
        },
        caption: {
            fontSize: '14px',
            color: '#FF5253',
        },
    },
});

ReactDOM.render(
    <CssBaseline>
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>
        </React.StrictMode>
    </CssBaseline>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
